<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" class="center-align text-center">
        <v-app-bar flat color="transparent">
          <v-spacer></v-spacer>
          <h1>QUYCKY!</h1>
          <v-spacer></v-spacer>
          <v-btn icon @click="logout()">
            <v-icon>logout</v-icon>
          </v-btn>
        </v-app-bar>
        <v-divider></v-divider>
      </v-col>
      <v-col cols="12" sm="12" md="7" lg="7" xl="5" offset-xl="2">
        <Rooms></Rooms>
      </v-col>
      <v-col cols="12" sm="12" md="5" lg="5" xl="3">
        <Users></Users>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
  import Vue from 'vue'
  import SocketIO from '@/services/SocketIO'
  import Auth from '@/services/Auth'
  import Users from './Users'
  import Rooms from './Rooms'
  export default {
    name: 'ListRooms',
    methods: {
      logout: function () {
        Auth.logout()
      }
    },
    created: function () {
      Vue.prototype.$socket = SocketIO.connect()
      console.log('socket connect', this.$socket);
    },
    destroyed: function () {
      this.$socket.disconnect()
      console.log('socket disconnect', this.$socket);
    },
    components: {
      Users,
      Rooms
    }
  }
</script>
