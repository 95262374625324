<template>
  <v-card class="mx-auto mt-8" elevation="20" tile>
    <v-app-bar dense flat color="transparent">
      <strong>{{rooms.length}} salas no momento...</strong>
      <v-spacer></v-spacer>
      <v-btn icon color="primary" @click="getRoomsUpdate()">
        <v-icon>refresh</v-icon>
      </v-btn>
    </v-app-bar>
    <v-divider v-if="rooms.length > 0"></v-divider>
    <v-row v-if="rooms.length > 0" class="mt-2">
      <v-col cols="12" sm="12" md="12" lg="6" v-for="room in rooms" :key="room.id">
        <Room
          :showQuestions="showRooms"
          :room="room"
        ></Room>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
  import Room from './Room'
  export default {
    name: 'Rooms',
    data: () => ({
      rooms: [],
      showRooms: true
    }),
    methods: {
      getRoomsUpdate: function () {
        this.$socket.emit('get-rooms-update')
      },
      onRoomsUpdate: function () {
        this.$socket.on('rooms-update', rooms => {
          console.log(rooms)
          this.rooms = rooms
          this.onUserWriting()
        })
      },
      onUserWriting: function () {
        this.rooms.forEach((room, roomKey) => {
          console.log(room, roomKey);
          this.$socket.on(`room.${room.name}.user-writing`, response => {
            console.log(response);
            this.addRoomQuestionUserWriting(roomKey, response)
          })
        })
      },
      addRoomQuestionUserWriting: function (roomKey, response) {
        const questionIndex = this.rooms[roomKey].questions.findIndex(question => question.id == response.question_id)
        if (questionIndex > -1) {
          const answers = this.rooms[roomKey].questions[questionIndex].answers
          if (answers) {
            const answerIndex = answers.findIndex(answer => answer.user_id == response.user.id)
            console.log('answerIndex', answerIndex)
            if (answerIndex > -1) {
              this.rooms[roomKey].questions[questionIndex].answers[answerIndex] = ({
                question_id: response.question_id,
                user: response.user,
                user_id: response.user.id,
                value: response.value
              })
            } else {
              this.rooms[roomKey].questions[questionIndex].answers.push({
                question_id: response.question_id,
                user: response.user,
                user_id: response.user.id,
                value: response.value
              })
            }
          } else {
            this.rooms[roomKey].questions[questionIndex]['answers'] = [{
              question_id: response.question_id,
              user: response.user,
              user_id: response.user.id,
              value: response.value
            }]
          }
        }
        this.showRooms = false
        this.$forceUpdate()
        setTimeout(() => {
          this.showRooms = true
          this.$forceUpdate()
        }, 100)
      }
    },
    mounted: function () {
      this.onRoomsUpdate()
      this.getRoomsUpdate()
    },
    components: {
      Room
    }
  }
</script>
