import Vue from 'vue'
import VueRouter from 'vue-router'
import Auth from '@/services/Auth'

Vue.use(VueRouter)

const router = new VueRouter({
  routes: [
    {
      path: '*',
      redirect: {
        name: 'login'
      }
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login'),
      meta: {
        auth: false,
        title: 'Login'
      }
    },
    {
      path: '/rooms',
      name: 'rooms',
      component: () => import('@/views/Rooms'),
      meta: {
        auth: true,
        title: 'Salas'
      }
    }
  ]
})

router.beforeEach(async (to, from, next) => {
  if (to.meta.auth) {
    if (Auth.check()) {
      setPageTitle(to?.meta?.title)
      return next()
    } else if (from.name != 'login') {
      router.push({name: 'login'})
    }
  } else {
    return next()
  }
})

const setPageTitle = function (title) {
  document.title = `${title} - Quycky`
  console.log(document.title)
}

export default router
