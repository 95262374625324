import { DEFAULT_USERNAME, DEFAULT_PASSWORD } from '@/constants/Environments'
import { LOCAL_STORAGE_TOKEN } from '@/constants/LocalStorage'

export default class Auth {
  static check () {
    const token = localStorage.getItem(LOCAL_STORAGE_TOKEN)
    if (token) {
      const { username, password } = JSON.parse(atob(token))
      return username == DEFAULT_USERNAME && password == DEFAULT_PASSWORD
    }
    return false
  }
  static logout () {
    localStorage.removeItem(LOCAL_STORAGE_TOKEN)
    document.location.reload(true)
  }
}