<template>
<v-dialog v-model="dialog" scrollable width="800">
  <v-card>
    <v-app-bar :dense="!user" color="transparent">
      <v-toolbar-title>
        <span v-if="user">
          <v-avatar size="50">
            <v-img v-if="user.avatar" :src="user.avatar" lazy-src="static/profile/profile.png"></v-img>
            <v-img v-else src="static/profile/profile.png" lazy-src="static/profile/profile.png"></v-img>
          </v-avatar>
          <strong class="ml-3">{{ user.name }} </strong>
        </span>
        <span v-else> Sala: {{ room.id }} </span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-title>
        <span v-if="user"> Sala: {{ room.id }} | </span>
        <span> Questões: {{ questions.length }} | </span>
        <span> Respostas: {{ answerCount }} </span>
      </v-toolbar-title>
      <v-btn icon @click="dialog = false">
        <v-icon>close</v-icon>
      </v-btn>
    </v-app-bar>
    <v-divider></v-divider>
    <v-card-text v-if="dialog">
      <v-card v-for="question in questions" :key="question.id" elevation="20" class="user-dialog__question">
        <v-app-bar dense flat>
          <span>{{ question.id }} - <strong> {{ question.description }}</strong></span>
          <v-spacer></v-spacer>
          <v-chip v-if="!question.answers || question.answers.length < 1"  outlined color="red">
            Não respondida ainda...
          </v-chip>
          <v-chip v-else  outlined color="green">
            {{ question.answers.length }} respostas
          </v-chip>
        </v-app-bar>
        <v-divider></v-divider>
        <v-card-text v-if="user && userNotAnswerQuestion(question)">
          <v-text-field
            v-model="userAnswer[question.id]"
            dense
            label="Resposta"
            placeholder="Digite sua resposta..."
            single-line
            hide-details
            outlined
            append-icon="send"
            :loading="answerLoader.indexOf(question.id) > -1"
            @click:append="sendAnswer(question.id)"
            @keyup="userAnswerText(question.id)"
            @keypress.enter="sendAnswer(question.id)"
          ></v-text-field>
        </v-card-text>
        <v-list v-if="question.answers && question.answers.length > 0" dense>
          <template v-for="(answer, answerIndex) in question.answers">
            <v-divider :key="`${answerIndex}-divider`"></v-divider>
            <v-list-item :key="answerIndex">
              <v-list-item-avatar>
                <v-img v-if="answer.user && answer.user.avatar" :src="answer.user.avatar" lazy-src="static/profile/profile.png"></v-img>
                <v-img v-else src="static/profile/profile.png" lazy-src="static/profile/profile.png"></v-img>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  <strong>{{ answer.user.name }}</strong>
                </v-list-item-title>
                <v-list-item-subtitle v-html="answer.value"></v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action v-if="user && answer.user_id != user.id && userNotPointAnswerQuestion(answer)">
                <v-btn icon color="red" @click="sendPoint(question, answer, hotPoint)">
                  <v-icon>local_fire_department</v-icon>
                </v-btn>
              </v-list-item-action>
              <v-list-item-action v-if="user && answer.user_id != user.id && userNotPointAnswerQuestion(answer)">
                <v-btn icon color="blue" @click="sendPoint(question, answer, coldPoint)">
                  <v-icon>ac_unit</v-icon>
                </v-btn>
              </v-list-item-action>
              <v-list-item-action v-if="user && answer.user_id == user.id">
                <v-chip color="orange" outlined small>
                  Minha resposta
                </v-chip>
              </v-list-item-action>
              <v-list-item-action v-if="answer.points && answer.points.length > 0">
                <v-chip color="black" small class="white--text">
                  Pontos: {{ answer.points.length }}
                </v-chip>
              </v-list-item-action>
            </v-list-item>
          </template>
        </v-list>
      </v-card>
    </v-card-text>
  </v-card>
</v-dialog>
</template>
<script>
  export default {
    name: 'UserDialog',
    data: () => ({
      userAnswer: {},
      hotPoint: {
        id: 1,
        description: 'hot',
        value: 10,
        created_at: null,
        updated_at: null
      },
      coldPoint: {
        id: 2,
        description: 'cold',
        value: 5,
        created_at: null,
        updated_at: null
      },
      answerLoader: [],
      pointLoader: []
    }),
    computed: {
      dialog: {
        get () {
          return this.value
        },
        set (value) {
          this.$emit('input', value)
        }
      },
      answerCount () {
        let count = 0
        this.questions.forEach(question => {
          count = count + (question?.answers?.length || 0)
        })
        return count
      }
    },
    methods: {
      sendAnswer: function (questionId) {
        this.$emit('sendAnswer', {
          question_id: questionId,
          value: this.userAnswer[questionId]
        })
        this.answerLoader.push(questionId)
      },
      sendPoint: function (question, answer, pointType) {
        this.$emit('sendPoint', {
          user_id: answer.user_id,
          question_id: question.id,
          answer_id: answer.id,
          level_id: question.level_id,
          point_type_id: pointType.id
        })
        this.pointLoader.push(question.id)
      },
      userNotAnswerQuestion: function (question) {
        let notResponse = true
        if (question?.answers && question?.answers?.length > 0) {
          question.answers.forEach(answer => {
            if (answer.user_id == this.user.id) {
              notResponse = false
            }
          })
        }
        return notResponse
      },
      userNotPointAnswerQuestion: function (answer) {
        let notResponse = true
        if (answer?.points && answer?.points?.length > 0) {
          answer.points.forEach(point => {
            if (point.user_give_id == this.user.id) {
              notResponse = false
            }
          })
        }
        return notResponse
      },
      userAnswerText: function (questionId) {
        const text = this.userAnswer[questionId]
        this.$emit('userWriting', {
          question_id: questionId,
          text: text || null
        })
      }
    },
    watch: {
      updated () {
        this.$forceUpdate()
      },
      showQuestions () {
        this.$forceUpdate()
      }
    },
    props: {
      value: {
        required: true
      },
      user: {
        default: null
      },
      room: {
        type: Object,
        default: () => ({})
      },
      questions: {
        type: Array,
        default: () => ([])
      },
      updated: {
        required: true,
        type: Boolean
      },
      showQuestions: {
        type: Boolean,
        required: true
      }
    }
  }
</script>
<style scoped>
  .user-dialog__question {
    margin: 20px 0;
  }
</style>
