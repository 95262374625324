<template>
  <v-content style="background-image: url('static/background/background-7.jpg')">
    <v-container fluid fill-height>
      <v-layout align-center justify-center>
        <v-flex xs12 sm8 md6 lg4 xl2>
          <v-card class="elevation-24 login" tile>
            <v-toolbar flat dense color="transparent">
              <v-toolbar-title>Login</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-divider></v-divider>
            <v-card-text class="mt-8">
              <v-form>
                <v-text-field
                  v-model="username"
                  prepend-icon="person"
                  label="Login"
                  outlined
                  @keyup.enter="login()"
                ></v-text-field>
                <v-text-field
                  v-model="password"
                  id="password"
                  prepend-icon="lock"
                  label="Password"
                  type="password"
                  outlined
                  @keyup.enter="login()"
                ></v-text-field>
              </v-form>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" block :disabled="!username || !password" @click="login()">
                <span>Login</span>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-content>
</template>
<script>
import { LOCAL_STORAGE_TOKEN } from '@/constants/LocalStorage'
import { DEFAULT_USERNAME, DEFAULT_PASSWORD } from '@/constants/Environments'
export default {
  name: 'Login',
  data: () => ({
    username: '',
    password: ''
  }),
  methods: {
    login: function () {
      if (this.loginValidate()) {
        this.saveToken()
        this.$router.push({ name: 'rooms' })
      } else {
        alert('Invalid login!')
      }
    },
    loginValidate: function () {
      return this.username == DEFAULT_USERNAME && this.password == DEFAULT_PASSWORD
    },
    saveToken: function () {
      const token = btoa(JSON.stringify({
        username: this.username,
        password: this.password
      }))
      localStorage.setItem(LOCAL_STORAGE_TOKEN, token)
    }
  }
}
</script>
<style scoped>
  .login {
    margin-top: 198px;
    margin-bottom: 518px;
  }
</style>