<template>
  <v-card flat outlined tile>
    <v-app-bar dense flat>
      <v-list-item class="room__item">
        <v-list-item-content>
          <v-list-item-title class="">
            <strong>ID: {{ room.id || 'Criando...' }}</strong>
            <span> - Usuários: {{ room.users.length }}</span>
          </v-list-item-title>
          <v-list-item-subtitle class="room__subtitle">
            <strong>NAME: {{ room.name }}</strong>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-spacer></v-spacer>
      <v-badge v-if="room.name" color="green" offset-x="20" offset-y="20" :value="answerCount" :content="answerCount">
        <v-btn icon color="orange" @click="dialog = true">
          <v-icon>quiz</v-icon>
        </v-btn>
      </v-badge>
      <v-btn v-if="room.name" icon color="red" @click="roomFinish()">
        <v-icon>delete_forever</v-icon>
      </v-btn>
    </v-app-bar>
    <v-divider></v-divider>
    <v-list dense>
      <template v-for="(user, userIndex) in room.users">
        <v-divider v-if="userIndex" :key="`${room.name}.divider.${userIndex}`"></v-divider>
        <v-list-item :key="`${room.name}.user.${userIndex}`">
          <v-list-item-avatar>
            <v-img v-if="user.avatar" :src="user.avatar" lazy-src="static/profile/profile.png"></v-img>
            <v-img v-else src="static/profile/profile.png" lazy-src="static/profile/profile.png"></v-img>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="">
              <span>ID: {{ user.id }}, </span>
              <strong>{{ user.name }}</strong>
            </v-list-item-title>
            <v-list-item-subtitle class="room__subtitle">
              <span>Socket: {{ user.hash }}</span>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>
    <UserDialog
      v-model="dialog"
      :room="room"
      :questions="room.questions"
      :updated="false"
      :showQuestions="showQuestions"
    ></UserDialog>
  </v-card>
</template>
<script>
  import UserDialog from './UserDialog'
  export default {
    name: 'Room',
    data: () => ({
      dialog: false
    }),
    computed: {
      answerCount () {
        let count = 0
        if (this.room?.questions?.length > 0) {
          this.room?.questions?.forEach(question => {
            count = count + (question?.answers?.length || 0)
          })
        }
        return count
      }
    },
    methods: {
      getUserImage: function (user) {
        return `https://randomuser.me/api/portraits/women/${user.id}.jpg`
      },
      roomFinish: function () {
        this.$socket.emit('room-finish', this.room)
        console.log('room-finish', this.room)
      },
      update: function () {
        this.$forceUpdate()
      }
    },
    watch: {
      showQuestions: 'update',
    },
    props: {
      room: {
        type: Object,
        required: true
      },
      showQuestions: {
        type: Boolean,
        required: true
      }
    },
    components: {
      UserDialog
    }
  }
</script>
<style scoped>
  .room__subtitle {
    font-size: 10px;
  }
  .room__item {
    padding: 0 !important;
  }
</style>