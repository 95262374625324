<template>
  <v-app dark>
    <v-main dark>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>
<script>
  export default {
    name: 'App'
  }
</script>