<template>
  <v-card class="mx-auto mt-8" elevation="20" max-width="700" tile>
    <v-app-bar dense flat color="transparent">
      <strong>Usuários</strong>
      <v-spacer></v-spacer>
      <v-btn outlined small color="primary" @click="createUser()">
        <span>Criar novo usuário</span>
      </v-btn>
    </v-app-bar>
    <v-divider></v-divider>
    <v-list dense>
      <v-divider></v-divider>
      <template v-for="user in users">
        <User :key="user.id" :user="user"></User>
        <v-divider :key="`${user.id}-divider`"></v-divider>
      </template>
    </v-list>
  </v-card>
</template>
<script>
  import User from './User'
  export default {
    name: 'Users',
    data: () => ({
      users: [],
      file: null
    }),
    methods: {
      createUsers: function () {
        for (let index = 1; index <= 5; index++) {
          this.createUser()
        }
      },
      createUser: function () {
        const userId = this.users.length + 1
        this.users.push({
          id: userId,
          name: `Player ${userId}`,
          avatar: `https://randomuser.me/api/portraits/women/${userId}.jpg`
        })
      }
    },
    mounted: function () {
      this.createUsers()
    },
    components: {
      User
    }
  }
</script>
