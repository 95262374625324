<template>
  <v-list-item>
    <v-list-item-avatar @click="dialog = true" style="cursor: pointer;">
      <v-img v-if="user.avatar" :src="user.avatar" lazy-src="static/profile/profile.png"></v-img>
      <v-img v-else src="static/profile/profile.png" lazy-src="static/profile/profile.png"></v-img>
    </v-list-item-avatar>
    <v-list-item-content @click="dialog = true" style="cursor: pointer;">
      <v-list-item-title>
        <span>ID: {{ user.id }}, </span>
        <strong>{{ user.name }}</strong>
      </v-list-item-title>
      <v-list-item-subtitle v-if="room && room.name">
        <strong v-if="room && room.users">Users: {{ room.users.length }}, </strong>
        <strong>Room: </strong>
        <span style="font-size: 10px;">{{ room.name }}</span>
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action v-if="questions && questions.length > 0">
      <v-card width="90" color="orange" flat class="text-center white--text pl-1 pr-1 mr-4">
        <strong>{{ questions.length }}</strong>
        <strong style="font-size: 12px;"> Questões</strong>
      </v-card>
    </v-list-item-action>
    <v-list-item-action v-else-if="roomIsComplete || (room && room.users && room.users.length >= 4)">
      <v-card width="90" color="orange" flat class="text-center black--text pl-1 pr-1 mr-4">
        <strong style="font-size: 12px;"> In game...</strong>
      </v-card>
    </v-list-item-action>
    <v-list-item-action v-if="status">
      <v-card v-if="roomIsComplete || (room && room.users && room.users.length >= 4)" width="90" color="blue" flat class="text-center white--text pl-1 pr-1 mr-5">
        <strong style="font-size: 12px;">Complete</strong>
      </v-card>
      <v-card v-else width="90" color="grey" flat class="text-center white--text pl-1 pr-1 mr-5">
        <strong style="font-size: 12px;">Waiting...</strong>
      </v-card>
    </v-list-item-action>
    <!-- <v-list-item-action v-if="status">
      <v-tooltip v-if="status" bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" icon color="red" @click="roomFinish()">
            <v-icon>delete_forever</v-icon>
          </v-btn>
        </template>
        <span>Deletar sala deste usuário</span>
      </v-tooltip>
    </v-list-item-action> -->
    <v-list-item-action v-if="status">
      <v-tooltip v-if="status" bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" icon color="green" @click="userExit()">
            <v-icon>logout</v-icon>
          </v-btn>
        </template>
        <span>Usuário saiu da sala</span>
      </v-tooltip>
    </v-list-item-action>
    <v-list-item-action v-if="status">
      <v-tooltip v-if="status" bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" icon color="green" @click="userFinish()">
            <v-icon>task_alt</v-icon>
          </v-btn>
        </template>
        <span>Usuário concluiu a sala</span>
      </v-tooltip>
    </v-list-item-action>
    <v-list-item-action>
      <v-tooltip v-if="status" bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" icon color="black" @click="disconnect()">
            <v-icon>close</v-icon>
          </v-btn>
        </template>
        <span>Se desconectar do Socket</span>
      </v-tooltip>
      <v-tooltip v-else bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" icon color="green" @click="connect()">
            <v-icon>login</v-icon>
          </v-btn>
        </template>
        <span>Se conectar ao Socket e emitir "get-my-room"</span>
      </v-tooltip>
    </v-list-item-action>
    <UserDialog
      v-model="dialog"
      :user="user"
      :socket="socket"
      :room="room"
      :questions="questions"
      :updated="updated"
      :showQuestions="updated"
      @sendAnswer="sendAnswer"
      @sendPoint="sendPoint"
      @userWriting="sendUserWriting"
    ></UserDialog>
  </v-list-item>
</template>
<script>
  import SocketIO from '@/services/SocketIO'
  import UserDialog from './UserDialog'
  export default {
    name: 'User',
    data: () => ({
      dialog: false,
      room: {},
      questions: [],
      socket: null,
      status: false,
      roomIsComplete: false,
      updated: false
    }),
    methods: {
      connect: function () {
        this.socket = SocketIO.connect()
        this.onConnect()
        this.onDisconnect()
        this.onGetMyRoom()
      },
      disconnect: function () {
        if (this.socket) {
          this.socket.disconnect()
        }
      },
      onConnect: function () {
        this.socket.on('connect', () => {
          this.status = true
          this.socket.emit('get-my-room', this.user)
        })
      },
      onDisconnect: function () {
        this.socket.on('disconnect', () => {
          this.status = false
          this.room = {}
          this.questions = []
          this.roomIsComplete = false
        })
      },
      onGetMyRoom: function () {
        this.socket.on('get-my-room', (room) => {
          console.log('get-my-room', room)
          this.room = room
          this.questions = room.questions || []
          this.onRoomAddUser()
          this.onRoomIsComplete()
          this.onReceiveAnswer()
          this.onReceivePoint()
          this.onUserExit()
          this.onUserFinish()
          this.onRoomFinish()
          this.onRoomFinish()
        })
      },
      onRoomAddUser: function () {
        this.socket.on(`room.${this.room.name}.add-user`, (data) => {
          this.room = data.room
          console.log(`room.${this.room.name}.add-user`, data);
        })
      },
      sendUserWriting : function ({ text, question_id }) {
        this.socket.emit('user-writing', {
          user: this.user,
          room_name: this.room.name,
          question_id: question_id,
          value: text
        })
      },
      onRoomUserDisconect: function () {
        this.socket.on(`room.${this.room.name}.user-disconnect`, (data) => {
          console.log(`room.${this.room.name}.user-disconnect`, data)
          this.room = data.room
        })
      },
      onRoomIsComplete: function () {
        this.socket.on(`room.${this.room.name}.is-complete`, ({ room, questions }) => {
          this.roomIsComplete = true
          this.room = room
          this.questions = questions
          console.log(`room.${this.room.name}.is-complete`, { room, questions });
        })
      },
      onRoomFinish: function () {
        this.socket.on(`room.${this.room.name}.finish`, (room) => {
          console.log('room.finish', room)
          this.disconnect()
        })
      },
      onUserExit: function () {
        this.socket.on(`room.${this.room.name}.user-exit`, (user) => {
          console.log('room.user-exit', user)
          if (user.id == this.user.id) {
            this.disconnect()
          }
        })
      },
      onUserFinish: function () {
        this.socket.on(`room.${this.room.name}.user-finish`, (data) => {
          console.log('room.user-finish', data)
          // this.disconnect()
        })
      },
      roomFinish: function () {
        this.socket.emit('room-finish', this.room)
        console.log(this.room)
      },
      userFinish: function () {
        this.socket.emit('user-finish', {
          user_id: this.user.id,
          room_name: this.room.name
        })
        console.log(this.room)
      },
      userExit: function () {
        this.socket.emit('user-exit', {
          user_id: this.user.id,
          room_name: this.room.name
        })
        console.log('user-exit', {
          user_id: this.user.id,
          room_name: this.room.name
        })
      },
      sendAnswer: function ({ question_id, value }) {
        const data = {
          user_id: this.user.id,
          room_id: this.room.id,
          room_name: this.room.name,
          question_id: question_id,
          value: value
        }
        console.log(`emit to: send-answer`, data);
        this.socket.emit(`send-answer`, data)
      },
      sendPoint: function ({ user_id, question_id, answer_id, level_id, point_type_id }) {
        const data = {
          user_id: user_id,
          user_give_id: this.user.id,
          room_id: this.room.id,
          question_id: question_id,
          answer_id: answer_id,
          level_id: level_id,
          point_type_id: point_type_id
        }
        console.log(`emit to: send-point`, data);
        this.socket.emit(`send-point`, data)
      },
      onReceiveAnswer: function () {
        this.socket.on(`room.${this.room.name}.receive-answer`, (answer) => {
          console.log(`room.${this.room.name}.receive-answer`, answer)
          const questionIndex = this.questions.findIndex(question => question.id == answer.question_id)
          if (questionIndex > -1) {
            if (this.questions[questionIndex].answers) {
              this.questions[questionIndex].answers.unshift(answer)
            } else {
              this.questions[questionIndex]['answers'] = [answer]
            }
          }
          this.$forceUpdate()
          this.updated = true
          setTimeout(() => {
            this.updated = false
          }, 500)
        })
      },
      onReceivePoint: function () {
        this.socket.on(`room.${this.room.name}.receive-point`, (point) => {
          console.log(`room.${this.room.name}.receive-point`, point)
          const questionIndex = this.questions.findIndex(question => question.id == point.question_id)
          if (questionIndex > -1) {
            if (this.questions[questionIndex].answers) {
              const answerIndex = this.questions[questionIndex].answers.findIndex(answer => answer.id == point.answer_id)
              if (answerIndex > -1) {
                if (this.questions[questionIndex].answers[answerIndex].points) {
                  this.questions[questionIndex].answers[answerIndex].points.unshift(point)
                } else {
                  this.questions[questionIndex].answers[answerIndex]['points'] = [point]
                }
              }
            }
          }
          this.$forceUpdate()
          this.updated = true
          setTimeout(() => {
            this.updated = false
          }, 500)
        })
      }
    },
    destroyed: function () {
      this.disconnect()
    },
    props: {
      user: {
        type: Object,
        required: true
      }
    },
    components: {
      UserDialog
    }
  }
</script>